<template>
  <div>
    <v-breadcrumbs v-if="!isEmbedded" :items="breadcrumbItems" />
    <v-card :elevation="isEmbedded ? 0 : 2">
      <EventHeader v-if="!isEmbedded && event && race" :event="event" :title="race.name" dark/>
      <RaceResultDetails :event="event" :race="race" :result="result" :filter="filter" :meta="meta" :badges="badges" :map="$route.query.map=='true'" />
    </v-card>

    <v-dialog v-if="highlightedBadge" v-model="highlightedBadge" width="600">
      <v-card>
        <v-card-title>
          {{ highlightedBadge.name }}
          <v-spacer/>
          <ShareButton v-if="!isEmbeddedInApp" color="primary" outlined/>
        </v-card-title>
        <img :src="highlightedBadge.img" style="max-width: 100%;"/>
      </v-card>
    </v-dialog>
    <v-dialog v-if="result" v-model="showShareCertificate" width="600">
      <v-card>
        <v-card-title>
          {{ event.name }}
          <v-spacer/>
          <ShareButton color="primary" outlined/>
        </v-card-title>
        <img :src="result.certificate_img" style="max-width: 100%;"/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showShareCertificate = false">{{$t('shared.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/eventbus.js';
import eventService from "@/services/eventService";
import EventHeader from "@/components/EventHeader.vue";
import RaceResultDetails from "@/components/RaceResultDetails";
import ShareButton from "@/components/ShareButton";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Race",
  components: {
    EventHeader,
    RaceResultDetails,
    ShareButton,
  },
  props: {
  },
  data() {
    return {
      eventId: null,
      raceId: null,
      resultId: null,
      highlightedBadge: null,
      event: null,
      race: null,
      result: null,
      badges: null,
      filter: null,
      meta: {}, // we don't have this here
      showShareCertificate: false,
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      this.eventId = this.$route.params.id;
      this.raceId = this.$route.params.raceId;
      this.resultId = this.$route.params.resultId;
      const code = this.$route.query.code;
      this.event = (await eventService.get(this.eventId)).data;
      this.race = this.event.races.find(x => x.id === this.raceId);
      this.meta = (await eventService.getRaceResultsMeta(this.eventId, this.raceId)).data;

      try {
        if (code && !this.resultId) {
          this.result = (await eventService.getRaceResultDetailsByCode(this.eventId, this.raceId, code)).data;
        }
        else {
          this.result = (await eventService.getRaceResultDetails(this.eventId, this.raceId, this.resultId)).data;
        }
      }
      catch (ex) {
        if (ex.response && ex.response.status == 404) {
          this.result = { };
        }
        //console.log('ERROR result', ex, ex.response);
      }

      var meta = null;
      var badgeId = this.$route.query.badge;
      if (badgeId) {
        this.highlightedBadge = this.result.badges.find(x => x.id == badgeId);
        //console.log('Sharing data for badge', highlightedBadge);
        meta = {
          title: `I earned the badge ${this.highlightedBadge.name} at the ${this.event.name}`,
          description: `I earned the badge ${this.highlightedBadge.name} at the ${this.event.name} on ${tenant.title}`,
          image: this.highlightedBadge.img,
        }; 
      }
      if (this.$route.query.share === 'certificate') {
        this.showShareCertificate = true;
        meta = {
          title: `I received my finish certificate for the ${this.race.name} at the ${this.event.name}`,
          description: `I completed the ${this.race.name} at the ${this.event.name} on ${tenant.title}. Check out my finisher certificate.`,
          image: this.result.certificate_img,
        }; 

      }
      meta = meta || {
        title: this.event.name + ' on ' + tenant.name,
        description: this.event.description,
        image: this.event.img,
      }; 
      EventBus.$emit('page-header-change', meta);
    },
  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race || !this.result ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: this.race.name, exact: true, to: { name: 'race', params: {id: this.event.id, raceId: this.race.id}} },
        { text: this.result.name, disabled: true },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
  },

};
</script>
<style lang="scss">

</style>

